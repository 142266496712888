.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
  }
  .work__item {
    cursor: pointer;
    color: var(--theme-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
    transition: 0.2s all ease;
  }
  .work__item:hover {
    background-color: var(--title-color-dark);
    color: var(--container-color);
  }
  
  .work__container {
    grid-template-columns: repeat(2, 0.42fr);
    /* grid-template-columns: repeat(2, max-content); */
    gap: 3rem;
    justify-content: center;
  }
  
  .work__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    border-radius: 1rem;
    /* max-width: 22rem; */
    align-items: center;
    transition: 0.2s all ease;
  }
  .work__card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  }
  .card-dark {
    background-color: var(--title-color);
    border: 1px solid var(--theme-color-lighter);
  }
  .card-dark:hover {
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.25);
  }
  .work__img-container {
    display: flex;
    justify-content: center;
  }
  .work__img {
    width: 295px;
    border-radius: 0.5rem;
    margin-bottom: var(--mb-1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  .work__text {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 1.25rem;
  }
  .work__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
  }
  .work__check {
    display: flex;
    justify-content: space-between;
  }
  .work__description {
    font-size: 1rem;
  }
  .work__button {
    color: var(--text-color);
    font-weight:var(--font-medium) ;
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    transition: 0.2s all ease;
  }
  .work__button:hover {
    color: var(--title-color-dark);
  }
  .work__button-icon {
    font-size: 1.25 rem;
  }
  .work__button:hover .work__button-icon {
    color: black;
  }
  
  .title-dark {
    color: var(--body-color);
  }
  .button-dark {
    color: var(--theme-color-lighter);
  }
  .button-dark:hover {
    color: var(--body-color);
  }
  
  /*active work*/
  .active-work {
    background-color: var(--title-color-dark);
    color: var(--container-color);
  }
  
  /*breakpoints*/
  /*for large devices*/
  @media screen and (max-width: 992px) {
    .work__container {
      gap: 1.5rem;
    }
    
    .work__card {
      padding: 1rem;
    }
    .work__img {
      margin-bottom: 0.75rem;
    }
    .work__title {
      margin-bottom: 0.25rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .work__container {
      grid-template-columns: 0.5fr;
    }
  }
  @media screen and (max-width: 576px) {
    .work__img {
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    .work__item {
      font-size: var(--small-font-size);
    }
    .work__filters {
      column-gap: 0.25rem;
    }
    .work__container {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 375px) {
    .work__check {
      font-size: var(--small-font-size);
    }
    .work__title {
      font-size: var(--small-font-size);
    }
    .work__description {
      font-size: var(--small-font-size);
    }
    .work__img {
      width: 85%;
    }
    .work__card {
      padding: 0.5rem;
      /* width: 15rem; */
      border-radius: 0.7rem;
    }
  }