#header {
    box-shadow: var(--box-shadow-l);
  }
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
  }
  
  .nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
  }
  .nav__icontoggle {
    position: absolute;
    right: 1.5rem;
    bottom: 1.25rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
    justify-content: center;
  }
  .nav__logo,
  .nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
  }
  .home__social-icon {
    font-size: 0.5rem;
    color: var(--title-color);
  }
  
  .nav__list {
    display: flex;
    column-gap: 2rem;
  }
  
  .nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--smal-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: 0.3s;
  }
  
  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }
  
  .active-link,
  .nav__link:hover {
    color: var(--title-color-dark);
    font-weight: bolder;
  }
  
  @media screen and (max-width: 768px) {
    .header {
      top: initial;
      bottom: 0;
    }
    .nav {
      height: var(--header-height);
    }
    .nav__menu {
      position: fixed;
      bottom: -100%;
      left: 0;
      width: 100%;
      background-color: var(--body-color);
      padding: 2rem 1.5rem 4rem;
      box-shadow: var(--box-shadow);
      border-radius: 1.5rem 1.5rem 0 0;
      transition: 0.3s;
    }
    .show-menu {
      bottom: 0;
    }
    .nav__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
    .nav__toggle {
      font-size: 1.1rem;
      cursor: pointer;
    }
    .nav__icon {
      font-size: 1.2rem;
    }
    .nav__icontoggle {
      position: absolute;
      left: 1.3rem;
      bottom: 0.75rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--title-color);
    }
    .nav__close {
      position: absolute;
      right: 1.3rem;
      bottom: 0.5rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--title-color);
    }
    .nav_close:hover {
      color: var(--title-color-dark);
    }
    .nav_toggle {
      font-size: 1.1rem;
      cursor: pointer;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle {
      display: block;
    }
  }
  
  .header {
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.15);
  }
  @media screen and (max-width: 350px) {
    .nav__menu {
      padding: 2rem 0.25rem 4rem;
    }
    .nav__list {
      column-gap: 0;
    }
  }