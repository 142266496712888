.skills__container {
    grid-template-columns: repeat(3, 350px);
    column-gap: 3rem;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .sklls__content {
    background-color: var(--container--color);
    border: var(--border-rad);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
    margin-bottom: 50px;
  }
  
  .skills__titlle {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
  }
  
  .section__title1 {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    font-size: 40px;
  }
  
  .skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
  }
  
  .skills__group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
  }
  
  .skills__data {
    display: flex;
    column-gap: 0.5rem;
  }
  
  .skills .bx-check-circle {
    font-size: 1rem;
    color: var(--title-color);
  }
  .skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
  }
  
  .skills__level {
    font-size: var(--small-font-size);
  }
  
  /*=============== BREAKPOINTS ===============*/
  /* For large devices */
  @media screen and (max-width: 1200px) {
    .skills__container {
      grid-template-columns: max-content;
      row-gap: 2rem;
    }
  }
  
  @media screen and (max-width: 576px) {
    .skills__container {
      grid-template-columns: 1fr;
    }
    .sklls__content {
      padding: 1.5rem;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .skills__container {
      grid-template-rows: max-content;
      column-gap: 2rem;
    }
    .skills__box {
      column-gap: 1.25rem;
    }
    .skills__name {
      font-size: var(--small-font-size);
    }
    .skills__level {
      font-size: var(--tiny-font-size);
    }
  }
  