.contact__container {
    justify-content: center;
    align-content: center;

}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;
}

.contact__form {
    width: 360px;
    margin-bottom: 70px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-div-dark {
    .contact__form-tag {
        background-color: var(--theme-color-dark);
    }
}

.contact__form-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    outline: none;
    border-radius: 0.75rem;
    padding: 1.5rem;
    z-index: 1;
}

.contact__form-tag {
    position: absolute;
    top: -0.75rem;
    left: 1.25rem;
    font-size: var(--smaller-font-size);
    padding: 0.25rem;
    background-color: var(--body-color);
    z-index: 10;
    font-weight: 700;
}
.invalid-label {
    color: red;
}
.contact__form-area {
    height: 11rem;
}
.contact__form-area textarea {
    resize: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .contact__container {
        column-gap: 3rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;
    }
    .contact__info {
        justify-content: center;
    }

    .contact__form {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {
    .contact__info {
        grid-template-columns: 1fr;
    }
    .contact__form {
        width: 100%;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
}